import React from "react";
import { IoIosMenu } from "react-icons/io";
import "./hamburgerMenuButton.css";
import { useNavigate } from "react-router-dom";
import RiffLogo from "./riffLogo.jsx";

function HamburgerMenuButton({ onClick }) {
  const navigate = useNavigate();

  return (
    <div className="hamburger-menu-container">
      <IoIosMenu className="hamburger-icon" onClick={onClick} />
      <div
        onClick={() => {
          navigate("/");
        }}
      >
        <RiffLogo className="header-riff-logo" />
      </div>
    </div>
  );
}

export default HamburgerMenuButton;
