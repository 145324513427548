import React, { useCallback, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./albumCheckout.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = () => {
  const { albumId } = useParams();
  const [clientSecret, setClientSecret] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const checkUserAuth = () => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setUserId(user.uid);
        } else {
          navigate("/signin", { state: { redirectToPayment: true, albumId } });
        }
      });

      return () => unsubscribe();
    };

    checkUserAuth();
  }, [auth, navigate, location, albumId]);

  const fetchClientSecret = useCallback(() => {
    if (!userId) {
      console.error("User ID is not available.");
      return;
    }

    console.log("Creating checkout session with:", { albumId, userId });

    return fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ albumId: albumId, userId: userId }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to create session");
        }
        return res.json();
      })
      .then((data) => {
        console.log("Session created:", data.clientSecret);
        setClientSecret(data.clientSecret);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [albumId, userId]);

  useEffect(() => {
    fetchClientSecret();
  }, [fetchClientSecret]);

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <div id="checkout">
      {clientSecret ? (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : (
        <p>Loading payment details...</p>
      )}
    </div>
  );
};

const Return = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get("session_id");
  const albumId = urlParams.get("album_id");

  useEffect(() => {
    const fetchSessionStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/session-status?session_id=${sessionId}`
        );
        const data = await response.json();
        console.log("Fetched session data:", data);
        setStatus(data.status);
        setCustomerEmail(data.customer_email);

        if (data.status === "complete") {
          navigate(`/album/${albumId}`);
        } else {
          navigate(`/checkout/${albumId}`);
        }
      } catch (error) {
        console.error("Error fetching session status:", error);
      }
    };
    fetchSessionStatus();
  }, [sessionId, albumId, navigate]);

  console.log(`transaction status is: ${status}`);

  return null;
};

export { CheckoutForm, Return };
