import React, { useRef, useState, useEffect, useCallback } from 'react';
import './PlayerNarrow.css';
import { Button } from '@mui/material';
import { FaCirclePlay, FaCirclePause } from "react-icons/fa6";
import { TiArrowShuffle } from "react-icons/ti";
import { MdSkipPrevious, MdSkipNext } from "react-icons/md";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import '../Font.css';

function PlayerNarrow({ album, track, trackIndex, tracks, setTrackIndex, setTrack }) {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const [progress, setProgress] = useState(0);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);

    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleMute = () => {
        if (isMuted) {
            audioRef.current.volume = volume;
        } else {
            audioRef.current.volume = 0;
        }
        setIsMuted(!isMuted);
    };

    const handleNextTrack = useCallback(() => {
        const nextTrackIndex = (trackIndex + 1) % tracks.length;
        setTrack({
            ...tracks[nextTrackIndex],
            audioFile: `${tracks[nextTrackIndex].audioFile}`,
        });
        setTrackIndex(nextTrackIndex);
    }, [trackIndex, tracks, setTrack, setTrackIndex]);

    const handlePreviousTrack = useCallback(() => {
        const prevTrackIndex = (trackIndex - 1 + tracks.length) % tracks.length;
        setTrack({
            ...tracks[prevTrackIndex],
            audioFile: `${tracks[prevTrackIndex].audioFile}`,
        });
        setTrackIndex(prevTrackIndex);
    }, [trackIndex, tracks, setTrack, setTrackIndex]);

    const handleProgress = () => {
        const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
        setProgress(progress);
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    useEffect(() => {
        const audio = audioRef.current;
        audio.addEventListener('timeupdate', handleProgress);
        audio.addEventListener('ended', handleNextTrack);
        return () => {
            audio.removeEventListener('timeupdate', handleProgress);
            audio.removeEventListener('ended', handleNextTrack);
        };
    }, [handleNextTrack]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.play();
        };
        setIsPlaying(true);
    }, [track.audioFile]);

    return (
        <div className="player-narrow">
            <div className="player-narrow-whole">
                <div className="player-narrow-info">
                    <p className="player-narrow-track-name">{track.name}</p>
                    <p className="player-narrow-artist-name">{album.artistName}</p>
                </div>
                <div className="player-narrow-play-button-container">
                    <button className={`player-narrow-play-button`} onClick={handlePlayPause}>
                        {isPlaying ? <FaCirclePause /> : <FaCirclePlay />}
                    </button>
                </div>
            </div>
            <div className="player-narrow-progress-bar-container">
                <span className='audio-minute'>{audioRef.current ? formatTime(audioRef.current.currentTime) : '0:00'}</span>
                <input
                    type="range"
                    className="progress-bar"
                    value={progress}
                    onChange={(e) => (audioRef.current.currentTime = (e.target.value / 100) * audioRef.current.duration)}
                    style={{
                        '--progress': `${progress}%`,
                    }}
                />
                <span className='audio-minute'>{audioRef.current ? formatTime(audioRef.current.duration) : '0:00'}</span>
            </div>
            <audio ref={audioRef} src={track.audioFile} preload="auto" />
        </div>
    );
}

export default PlayerNarrow;