import React, { useEffect, useState } from "react";
import "./Album.css";
import Track from "./Components/Track.jsx";
import Player from "./Components/Player.jsx";
import AlbumImage from "./Components/albumImage.jsx";
import AlbumTabSelection from "./Components/albumTabSelection.jsx";
import "./Font.css";
import { useAuth } from "./AuthContext.js";
import { useNavigate } from "react-router-dom";
import Modal from "./Components/Modal.jsx";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./Firebase.js";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import PlayerNarrow from "./Components/PlayerNarrow.jsx";
import { useMediaQuery } from "react-responsive";
import { IoIosLock } from "react-icons/io";

function Album() {
  const navigate = useNavigate();
  const [albumData, setAlbumData] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const { currentUser } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const { albumId } = useParams();
  const [activeTab, setActiveTab] = useState("Track");
  const isNarrowScreen = useMediaQuery({ query: "(max-width: 760px)" });
  const [userHasPaid, setUserHasPaid] = useState(false);

  useEffect(() => {
    const fetchAlbum = async () => {
      const albumRef = doc(db, "albums", albumId);
      const albumSnap = await getDoc(albumRef);

      if (albumSnap.exists()) {
        const albumData = albumSnap.data();

        // Check if the user has paid for this album
        if (currentUser) {
          const userRef = doc(db, "users", currentUser.uid);
          const userSnap = await getDoc(userRef);
          const userData = userSnap.data();
          const hasPaid = userData.paidAlbums.includes(albumId);
          setUserHasPaid(hasPaid);

          // Only fetch audio URLs if the user has paid
          if (hasPaid) {
            const storage = getStorage();
            const tracks = await Promise.all(
              albumData.tracks.map(async (track) => {
                const audioRef = ref(
                  storage,
                  `audio/${albumId}/${track.audioFile}`
                );
                track.audioFile = await getDownloadURL(audioRef);
                return track;
              })
            );
            setAlbumData({ ...albumData, tracks });
          } else {
            setAlbumData(albumData); // Set album data without audio URLs
          }
        } else {
          setAlbumData(albumData); // Set album data without audio URLs
        }
      } else {
        console.error("No such album!");
      }
    };

    fetchAlbum();
  }, [albumId, currentUser]);

  if (!albumData) {
    return <p>Loading album data...</p>;
  }

  const handleSupport = () => {
    if (!currentUser) {
      window.localStorage.setItem(
        "redirectAfterSignIn",
        JSON.stringify({ to: "/checkout", albumId })
      );

      navigate("/signin", { state: { redirectToPayment: true, albumId } });
      return;
    }

    if (userHasPaid) {
      navigate("/signin", { state: { redirectToPayment: false, albumId } });
    }

    navigate(`/checkout/${albumId}`);
  };

  const handleTrackClick = (track, index) => {
    if (!userHasPaid) {
      setModalOpen(true);
      return;
    }

    setCurrentTrack({
      ...track,
      audioFile: track.audioFile,
    });

    setCurrentTrackIndex(index);
  };

  return (
    <div className="album-page">
      <div className="album-contents">
        <h1 className="album-page-artist-name">{albumData.artistName}</h1>

        <div className="card"></div>
        <div className="album-page-album-image-container">
          <AlbumImage
            className={`album-page-album-image ${
              !userHasPaid ? "album-page-album-image-blurred" : ""
            }`}
            imageName={albumData.albumImage}
            altText={albumData.albumImageAlt}
          />
          {!userHasPaid && <IoIosLock className="album-page-lock-icon" />}
        </div>
        <h2 className="album-page-album-name">{albumData.albumName}</h2>
        <div className="album-page-album-description">
          <p className="album-page-album-description-item">
            Release date: {albumData.albumReleaseDate}/
            {albumData.albumReleaseMonth}/{albumData.albumReleaseYear}
          </p>
        </div>
        <AlbumTabSelection activeTab={activeTab} setActiveTab={setActiveTab} />
        <div className="tab-content">
          {activeTab === "About" && (
            <div className="about-tab">
              <p>{albumData.albumDescription}</p>
            </div>
          )}
          {activeTab === "Track" && (
            <div className="track-list">
              {albumData.tracks.map((track, index) => (
                <Track
                  key={index}
                  onClick={() => handleTrackClick(track, index)}
                  name={track.name}
                />
              ))}
            </div>
          )}
        </div>
        {currentTrack &&
          (isNarrowScreen ? (
            <PlayerNarrow
              album={albumData}
              track={currentTrack}
              trackIndex={currentTrackIndex}
              tracks={albumData.tracks}
              setTrackIndex={setCurrentTrackIndex}
              setTrack={setCurrentTrack}
            />
          ) : (
            <Player
              album={albumData}
              track={currentTrack}
              trackIndex={currentTrackIndex}
              tracks={albumData.tracks}
              setTrackIndex={setCurrentTrackIndex}
              setTrack={setCurrentTrack}
            />
          ))}
        {isModalOpen && (
          <Modal
            album={albumData}
            onClose={() => setModalOpen(false)}
            onSupport={handleSupport}
          />
        )}
      </div>
    </div>
  );
}

export default Album;
