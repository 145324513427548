import React from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";

function Home() {
  const navigate = useNavigate();

  const goToAllAlbums = () => {
    navigate("/all-albums");
  };

  return (
    <div className="home">
      <div className="home-video-container">
        <video autoPlay loop muted playsInline>
          <source src="/cover-video/riff_cover_video_v2.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay-text">
          <h1>Riff, where artists and fans connect.</h1>
          <p>Celebrate your music fandom and help your artists grow</p>
          <button className="home-navigate-button" onClick={goToAllAlbums}>
            Explore all pre-releases
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
