import React from "react";
import "./About.css"; // Assuming you have a CSS file for styling

function About() {
  return (
    <div className="about-page about-page-fade-in">
      <h1>What is Riff?</h1>
      <p>
        Riff is a platform that celebrates music fandom and empowers artists to
        grow. Our mission is to create a more connected world through the power
        of music, where fans and artists come together in a shared love for
        creativity and expression. Our first product is focused on album
        pre-releases, allowing fans to be the first to listen to their favorite
        artists' music before it’s available anywhere else. By bridging the gap
        between artists and their audience, Riff fosters a deeper connection and
        an exclusive experience for music lovers.
      </p>
      <h1>How much my favourite artists will get from Riff?</h1>
      <p>
        At Riff, we believe that artists should be fairly compensated for their
        work. That’s why we don’t take any fees from artists for using our
        platform. After covering the necessary administrative costs, such as
        transaction processing fees and storage expenses, every bit of revenue
        goes directly to the artists. Our goal is to support the creative
        community by ensuring that artists receive the full value of their
        contributions, helping them to thrive and continue producing the music
        we all love.
      </p>
      <h1>You are an artist and interested to use our product?</h1>
      <p>
        Drop us a message at <a href="mailto:riff@riff.im">riff@riff.im</a>!
      </p>
    </div>
  );
}

export default About;
