import React from 'react';
import './Track.css';  // Assuming you might want to style the track items specifically

function Track({ key, name, onClick }) {
    return (
        <div key={key} onClick={onClick} className="track-item">
            {name}
        </div>
    );
}

export default Track;
