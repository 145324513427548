import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "./Firebase.js";
import { useAuth } from "./AuthContext.js";
import "./allAlbums.css";
import AlbumImage from "./Components/albumImage.jsx";
import { IoIosLock } from "react-icons/io";

function AllAlbums() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [albums, setAlbums] = useState([]);
  const [paidAlbums, setPaidAlbums] = useState([]);

  useEffect(() => {
    const fetchAlbums = async () => {
      const albumsCollection = collection(db, "albums");
      const albumSnapshot = await getDocs(albumsCollection);
      const albumList = albumSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAlbums(albumList);
    };

    const fetchPaidAlbums = async () => {
      if (currentUser) {
        const userRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setPaidAlbums(userDoc.data().paidAlbums || []);
        }
      }
    };

    fetchAlbums();
    fetchPaidAlbums();
  }, [currentUser]);

  const handleAlbumClick = (albumId) => {
    navigate(`/album/${albumId}`);
  };

  return (
    <div className="all-albums">
      <div className="all-albums-album-list">
        {albums.map((album) => {
          const isPaid = paidAlbums.includes(album.id);
          const isBlurred = !currentUser || !isPaid;

          return (
            <div
              key={album.id}
              className="all-albums-album-item"
              onClick={() => handleAlbumClick(album.id)}
            >
              <div className="all-albums-album-image-container">
                <AlbumImage
                  className={`all-albums-album-image ${
                    isBlurred ? "all-albums-album-image-blurred" : ""
                  }`}
                  imageName={album.albumImage}
                  altText={album.albumImageAlt}
                />
                {isBlurred && <IoIosLock className="all-albums-lock-icon" />}
              </div>
              <div className="all-albums-album-details">
                <p className="all-albums-album-name">{album.albumName}</p>
                <p className="all-albums-album-artist">@{album.artistTag}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AllAlbums;
