import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import SignIn from "./Components/signIn.js";
import Album from "./Album.jsx";
import { CheckoutForm, Return } from "./Components/albumCheckout.jsx";
import FinishSignIn from "./Components/finishSignIn.jsx";
import SideMenu from "./Components/sideMenu.jsx";
import { AuthProvider } from "./AuthContext.js";
import HamburgerMenuButton from "./Components/hamburgerMenuButton.jsx";
import Home from "./Home.jsx";
import About from "./About.jsx";
import AllAlbums from "./allAlbums.jsx";

function App() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  return (
    <Router>
      <AuthProvider>
        <div className="app-container">
          <HamburgerMenuButton onClick={toggleMenu} />
          <SideMenu isOpen={isMenuOpen} onClose={toggleMenu} />
          <div className="main-content">
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/album/:albumId" element={<Album />} />
              <Route path="/" element={<Home />} />
              <Route path="/checkout/:albumId" element={<CheckoutForm />} />
              <Route path="/return" element={<Return />} />
              <Route path="/finishSignIn" element={<FinishSignIn />} />
              <Route path="/about" element={<About />} />
              <Route path="/all-albums" element={<AllAlbums />} />
            </Routes>
          </div>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
