// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDofeaGlW7klbscF4XaPpk8I2S0SgUTGUU",
  authDomain: "riff-f6837.firebaseapp.com",
  projectId: "riff-f6837",
  storageBucket: "riff-f6837.appspot.com",
  messagingSenderId: "1060118942615",
  appId: "1:1060118942615:web:bd7db03397e62c28c8aad2",
  measurementId: "G-ETB76T6B65"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

export { db, auth };