import React, { createContext, useContext, useState, useEffect } from "react";
import {
  sendSignInLinkToEmail,
  signInWithEmailLink,
  isSignInWithEmailLink,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db } from "./Firebase.js";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (!userDoc.exists()) {
            await setDoc(doc(db, "users", user.uid), {
              email: user.email,
              paidAlbums: [],
              userTag: user.email.split("@")[0],
            });
            console.log("User document created successfully");
          }
          setCurrentUser(user);
        } catch (error) {
          console.error("Error during Firestore operation:", error);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const sendSignInLink = async (email) => {
    const actionCodeSettings = {
      url: `${window.location.origin}/finishSignIn`, // Redirect back to your app after sign-in
      handleCodeInApp: true,
    };

    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(
          "Error while sending signin link: " + errorCode + errorMessage
        );
      });
  };

  const completeSignIn = async (email, link) => {
    if (isSignInWithEmailLink(auth, link)) {
      const result = await signInWithEmailLink(auth, email, link);
      return result.user;
    }
    return null;
  };

  const logOut = () => {
    return signOut(auth);
  };

  const value = {
    currentUser,
    sendSignInLink,
    completeSignIn,
    logOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
