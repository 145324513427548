import React from "react";
import "./sideMenu.css";
import { useAuth } from "../AuthContext.js";
import { useNavigate } from "react-router-dom";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import RiffLogo from "./riffLogo";

function SideMenu({ isOpen, onClose }) {
  const { currentUser, logOut } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logOut();
    navigate("/");
  };

  const handleSignIn = () => {
    navigate("/signin");
  };

  return (
    <>
      <div className={`side-menu ${isOpen ? "open" : ""}`}>
        <div className="side-menu-content">
          <RiffLogo className="side-menu-logo" />
          <ul className="menu-list">
            <li
              onClick={() => {
                navigate("/all-albums");
                onClose();
              }}
            >
              All pre-releases
            </li>
            <li
              onClick={() => {
                navigate("/about");
                onClose();
              }}
            >
              About Riff
            </li>
            <li
              onClick={() => {
                onClose();
              }}
            >
              <a href="mailto:riff@riff.im">Contact us</a>
            </li>
          </ul>
          {currentUser ? (
            <div className="bottom-section">
              <button
                className="side-menu-login-logout"
                onClick={() => {
                  handleLogout();
                  onClose();
                }}
              >
                Logout
              </button>
              <div className="profile">
                <AccountCircleRoundedIcon className="profile-picture" />
                <p>{currentUser.email}</p>
              </div>
            </div>
          ) : (
            <div className="bottom-section">
              <button
                className="side-menu-login-logout"
                onClick={() => {
                  handleSignIn();
                  onClose();
                }}
              >
                Sign In
              </button>
            </div>
          )}
        </div>
      </div>
      {isOpen && <div className="side-menu-overlay" onClick={onClose}></div>}
    </>
  );
}

export default SideMenu;
