import React, { useState } from "react";
import { useAuth } from "../AuthContext.js";
import { useLocation } from "react-router-dom";
import RiffLogo from "./riffLogo.jsx";
import "./signIn.css";

function SignIn() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { sendSignInLink } = useAuth();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendSignInLink(email);
      setMessage("Click the link from your email to sign in!");

      const redirectInfo = location.state?.redirectToPayment
        ? { to: "/checkout", albumId: location.state.albumId }
        : { to: location.state?.from || "/" };

      window.localStorage.setItem(
        "redirectAfterSignIn",
        JSON.stringify(redirectInfo)
      );
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-box">
        <RiffLogo className="signin-riff-logo"></RiffLogo>
        <h2>Sign in and feel the riff</h2>
        <form onSubmit={handleSubmit} className="signin-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            required
            className="email-input"
          />
          <button type="submit" className="submit-button">
            →
          </button>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
}

export default SignIn;
