import React from "react";

function RiffLogo({ imageName, altText, className }) {
  return (
    <img
      className={className}
      src="/brand-logo/riff_logo_2.png"
      alt="Riff brand logo"
    />
  );
}

export default RiffLogo;
