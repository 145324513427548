import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

function AlbumImage({ imageName, altText, className }) {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const fetchImageUrl = async () => {
            const storage = getStorage();
            const imageRef = ref(storage, `album-images/${imageName}`);

            try {
                const url = await getDownloadURL(imageRef);
                setImageUrl(url);
            } catch (error) {
                console.error("Error fetching image URL:", error);
            }
        };

        fetchImageUrl();
    }, [imageName]);

    if (!imageUrl) {
        return <p>Loading image...</p>;
    }

    return <img className={className} src={imageUrl} alt={altText} />;
}

export default AlbumImage;