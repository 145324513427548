import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext.js";
import { getFirestore, doc, getDoc } from "firebase/firestore";

function FinishSignIn() {
  const { completeSignIn, currentUser } = useAuth();
  const navigate = useNavigate();
  const hasRun = useRef(false); // Use a ref to keep track of execution
  const db = getFirestore();
  const [error, setError] = useState(null);

  useEffect(() => {
    const email = window.localStorage.getItem("emailForSignIn");
    if (hasRun.current) return; // If already run, return early

    const finishSignIn = async () => {
      try {
        hasRun.current = true; // Set the flag to true
        const user = await completeSignIn(email, window.location.href);

        // Retrieve redirect info from local storage
        const redirectInfo = JSON.parse(
          window.localStorage.getItem("redirectAfterSignIn")
        );

        console.log("Redirect Info:", redirectInfo);
        console.log("finishSignIn running");

        if (redirectInfo?.to === "/checkout" && redirectInfo.albumId) {
          // Check if the user has already paid for the album
          const userRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            const paidAlbums = userData.paidAlbums || [];

            if (paidAlbums.includes(redirectInfo.albumId)) {
              // If the album is already paid for, redirect to the album page
              console.log(
                `Navigating to /album/${redirectInfo.albumId} (already paid)`
              );
              navigate(`/album/${redirectInfo.albumId}`);
            } else {
              // If not, continue to the checkout page
              console.log(`Navigating to /checkout/${redirectInfo.albumId}`);
              navigate(`/checkout/${redirectInfo.albumId}`);
            }
          } else {
            // If the user is not in the database then by default the user didn't pay so directly to checkout
            console.log(
              `User not existed in the database. Navigating to /checkout/${redirectInfo.albumId}`
            );
            navigate(`/checkout/${redirectInfo.albumId}`);
          }
        } else if (redirectInfo?.to) {
          navigate(redirectInfo.to, {
            state: { albumId: redirectInfo.albumId },
          });
        } else {
          console.log("Navigating to / (default)");
          navigate("/"); // Default redirect after sign-in
        }

        // Clear local storage
        window.localStorage.removeItem("redirectAfterSignIn");
      } catch (error) {
        console.error("Error during sign-in:", error);
        setError(error.message);
      }
    };

    finishSignIn();
  }, [completeSignIn, navigate, db]);

  return (
    <div>
      {error ? (
        <p>
          Error: {error} Please use the same browser throughout the whole login
          process if it is not the case.
        </p> // Display the error message
      ) : (
        <p>Finishing sign-in...</p> // Display the original message if no error
      )}
    </div>
  );
}

export default FinishSignIn;
