import React from "react";
import "./albumTabSelection.css";

function AlbumTabSelection({activeTab, setActiveTab}) {

    return (
        <div className="tab-navigation">
            <button
                className={activeTab === 'About' ? 'tab-button active' : 'tab-button'}
                onClick={() => setActiveTab('About')}
            >
                About
            </button>
            <button
                className={activeTab === 'Track' ? 'tab-button active' : 'tab-button'}
                onClick={() => setActiveTab('Track')}
            >
                Track
            </button>
        </div>
    )
}

export default AlbumTabSelection;