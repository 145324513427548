import React from "react";
import "./Modal.css"; // Style the modal as per your design
import AlbumImage from "./albumImage.jsx";
import { Link } from "react-router-dom";

function Modal({ album, onClose, onSupport }) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <AlbumImage
            className="modal-album-image"
            imageName={album.albumImage}
            altText={album.albumImageAlt}
          />
          <h2>Real fans only!</h2>
        </div>
        <p className="modal-description">
          <span className="modal-support-text">Support</span> {album.artistName}{" "}
          and listen to {album.albumName} before anyone else.
        </p>
        <button
          className="modal-button modal-support-button"
          onClick={onSupport}
        >
          Listen now at {album.albumPrice} {album.albumPriceCurrency}
        </button>
        <button className="modal-button modal-close-button" onClick={onClose}>
          Not now
        </button>
        <Link to="/About" className="modal-info-link">
          Understand how much your artist will get
        </Link>
      </div>
    </div>
  );
}

export default Modal;
